import React from 'react';
import { Route } from 'react-router-dom';
import FeedbackLayout from '../layouts/feedback/FeedbackLayout';
import FeedbackPage from '../pages/feedbackPage/FeedbackPage';
import FeedbackSuccessPage from '../pages/feedbackSuccessPage/FeedbackSuccessPage';
import FeedbackFailPage from '../pages/feedbackFailPage/FeedbackFailPage';
import FeedbackSubmitPage from '../pages/feedbackSubmitPage/FeedbackSubmitPage';

import { AppRoute } from '../constants/routes';

const APP_ROUTES = [
  { path: AppRoute.FEEDBACK, component: FeedbackPage },
  { path: AppRoute.FEEDBACK_SUCCESS, component: FeedbackSuccessPage },
  { path: AppRoute.FEEDBACK_FAIL, component: FeedbackFailPage },
  { path: AppRoute.FEEDBACK_SUBMIT, component: FeedbackSubmitPage },
];

export function appRoutes() {
  return APP_ROUTES.map(({ path, component: Component }) => (
    <Route key={path} path={path} exact>
      <FeedbackLayout>
        <Component />
      </FeedbackLayout>
    </Route>
  ));
}
