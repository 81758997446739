import React from 'react';
import type { IVisitInfo } from '../interfaces/visit-info.interface';
import { fetchVisitInfoBySlug } from '../api';
import { setLocalStorageItem } from '../helpers/localStorageHelper';

type Props = { slug?: string; isWelcomeRequest?: boolean };

const useVisitInfo = ({ slug, isWelcomeRequest = false }: Props) => {
  const [info, setInfo] = React.useState<IVisitInfo | null>(null);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (slug) {
      fetchVisitInfoBySlug(slug, isWelcomeRequest)
        .then((info) => setInfo(info))
        .catch(() => setError(true));
    }
  }, [slug]);

  React.useEffect(() => {
    if (info) {
      setLocalStorageItem('info', info);
    }
  }, [info]);

  return [{ info, error }];
};

export default useVisitInfo;
