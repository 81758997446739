import React from 'react';
import { Typography } from '@mui/material';

function HomePage() {
  return (
    <Typography variant='body2' sx={{ padding: 2 }}>
      Некорректная ссылка
    </Typography>
  );
}

export default HomePage;
