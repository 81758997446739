import baseAxios from 'axios';
import { API_RESPONSE_STATUS_CODE, BASE_URL } from './constants/app';

const api = baseAxios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  (options) => options,
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export const doGet = (url: string, options: any) => api.get(url, options);
export const doPost = (url: string, options: any) => api.post(url, options);

export const fetchVisitInfoBySlug = async (
  slug: string,
  isWelcomeRequest: boolean
) => {
  try {
    const result = await doGet(`/api/visit/${slug}/info`, {
      params: { isWelcomeRequest },
    });
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    throw new Error();
  }
};

export const createFeedback = async (
  slug: string,
  rating: number,
  text: string,
  doctorId?: number
) => {
  try {
    const result = await doPost(`/api/visit/${slug}/feedback`, {
      rating,
      text,
      doctorId,
    });
    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const fetchDoctorsByClinicId = async (clinicId: number) => {
  try {
    const result = await doGet(`/api/doctor`, {
      params: { clinicId },
    });
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    throw new Error();
  }
};

export default api;
