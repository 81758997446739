export enum Service {
  YANDEX = 'YANDEX',
  GIS = 'GIS',
  PRO = 'PRO',
  GOOGLE = 'GOOGLE',
  NAPOPRAVKU = 'NAPOPRAVKU',
  DOCDOC = 'DOCDOC',
}

export enum FEEDBACK_SITE_NAMES {
  PRODOCTOROV = 'PRODOCTOROV',
  TWOGIS = 'TWOGIS',
  YANDEXMAP = 'YANDEXMAP',
  GOOGLEMAPS = 'GOOGLEMAPS',
  NAPOPRAVKU = 'NAPOPRAVKU',
  DOCDOC = 'DOCDOC',
}
