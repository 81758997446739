import React from 'react';
import { Box, Container } from '@mui/material';
import bgPart from './bg-part.png';
import './feedbackLayout.css';

import '@fontsource/nunito-sans/400.css';
import '@fontsource/nunito-sans/600.css';
import '@fontsource/nunito-sans/700.css';

function FeedbackLayout({ children }: { children: React.ReactElement }) {
  return (
    <Container
      className='feedback-layout'
      maxWidth={false}
      sx={{ backgroundImage: `url(${bgPart})`, padding: 0, minHeight: '100vh' }}
    >
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
        }}
      >
        {children}
      </Box>
    </Container>
  );
}

export default FeedbackLayout;
