import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import personOkImage from './person-ok.png';

function FeedbackSubmitPage() {
  return (
    <Box className='feedback-page'>
      <Card className='card card-ok'>
        <Box className='card__image-wrap'>
          <img className='card__image' src={personOkImage} alt='person' />
        </Box>
        <CardContent sx={{ padding: '24px 0 149px' }}>
          <Typography
            sx={{
              fontFamily: '"Nunito Sans", sans-serif',
              mb: 2,
            }}
            fontSize={24}
            lineHeight={'32px'}
            fontWeight={600}
            textAlign='center'
          >
            Спасибо за сообщение!
          </Typography>
          <Typography
            sx={{ fontFamily: '"Nunito Sans", sans-serif', mb: 1 }}
            fontSize={16}
            lineHeight={'24px'}
            fontWeight={600}
            textAlign='center'
          >
            Мы вернемся к Вам с решением.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default FeedbackSubmitPage;
