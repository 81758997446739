import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from '@mui/material';
import personFailImage from './person-fail.png';
import textareaIcon from './textarea.svg';

import { AppRoute } from '../../constants/routes';
import { createFeedback } from '../../api';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from '../../helpers/localStorageHelper';

const FAIL_RATING = 1;

function FeedbackFailPage() {
  const { slug }: { slug?: string } = useParams();

  const [text, setText] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [info] = React.useState(() => getLocalStorageItem('info'));

  if (!slug || !info) {
    removeLocalStorageItem('info');

    return <Redirect to={AppRoute.HOME} />;
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    const feedback = await createFeedback(slug, FAIL_RATING, text);
    if (feedback) {
      setSuccess(true);
    }
    setIsLoading(false);
  };

  if (success) {
    removeLocalStorageItem('info');
    return (
      <Redirect to={getUpdatedSlugRoute(AppRoute.FEEDBACK_SUBMIT, slug)} />
    );
  }

  return (
    <Box className='feedback-page'>
      <Card className='card'>
        <Box className='card__image-wrap'>
          <img className='card__image' src={personFailImage} alt='person' />
        </Box>
        <CardContent sx={{ padding: '32px 0 40px' }}>
          <Typography
            sx={{
              fontFamily: '"Nunito Sans", sans-serif',
              mb: 2,
            }}
            fontSize={24}
            lineHeight={'32px'}
            fontWeight={600}
          >
            Напишите руководству
          </Typography>
          <Typography
            sx={{
              fontFamily: '"Nunito Sans", sans-serif',
              mb: 1,
              whiteSpace: 'pre-wrap',
            }}
            fontSize={16}
            lineHeight={'24px'}
            fontWeight={600}
          >
            {info.failureMessage}
          </Typography>
          <TextField
            sx={{
              '& textarea': {
                resize: 'vertical',
                backgroundImage: `url(${textareaIcon})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '24px',
                backgroundPosition: '100% 100%',
                overflow: 'hidden',
              },
              '& fieldset': {
                border: 'none',
              },
              '& .MuiInputLabel-root': {
                fontFamily: '"Nunito Sans", sans-serif',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
                top: '8px',
                left: '16px',
                color: '#828282',
                transform: 'none',
              },
              '& .MuiInputLabel-root.MuiInputLabel-shrink': {
                fontSize: '11px',
                lineHeight: '16px',
                fontWeight: 600,
                transform: 'none',
                top: '8px',
                left: '16px',
                color: '#828282',
              },
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#FFFFFF',
                borderRadius: '24px',
                border: '1px solid #98B6F1',
                padding: '24px 8px 8px 16px',
              },
              '& .MuiOutlinedInput-root.Mui-focused': {
                borderColor: '#316EE5',
              },
            }}
            id='feedback'
            autoFocus
            label='Опишите ситуацию'
            multiline
            fullWidth
            rows={4}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </CardContent>
        <CardActions
          disableSpacing={true}
          sx={{ display: 'grid', alignSelf: 'stretch', padding: 0 }}
        >
          <Button
            className='button success'
            variant='contained'
            size='large'
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Отправить
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

const getUpdatedSlugRoute = (route: string, slug: string) => {
  return route.replace(':slug', slug);
};

export default FeedbackFailPage;
