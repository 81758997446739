import React from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import personImage from './person.png';

import type { IVisitInfo } from '../../interfaces/visit-info.interface';
import useVisitInfo from '../../hooks/useVisitInfo';
import { AppRoute } from '../../constants/routes';
import { removeLocalStorageItem } from '../../helpers/localStorageHelper';

import './feedbackPage.css';

function FeedbackPage() {
  const { slug }: { slug?: string } = useParams();
  const [{ info, error }] = useVisitInfo({ slug, isWelcomeRequest: true });

  if (!slug || error) {
    removeLocalStorageItem('info');

    return <Redirect to={AppRoute.HOME} />;
  }

  if (!info) {
    return <span>loading...</span>;
  }

  return (
    <Box className='feedback-page'>
      <Card className='feedback-page__card card'>
        <Box className='card__image-wrap'>
          <img className='card__image' src={personImage} alt='person' />
        </Box>
        <CardContent sx={{ padding: '32px 0 48px' }}>
          <Typography
            sx={{
              fontFamily: '"Nunito Sans", sans-serif',
              mb: 2,
            }}
            fontSize={24}
            lineHeight={'32px'}
            fontWeight={600}
          >
            {info.message}
          </Typography>
          <Typography
            sx={{ fontFamily: '"Nunito Sans", sans-serif' }}
            fontSize={16}
            lineHeight={'24px'}
            fontWeight={600}
          >
            Расскажите, понравилось ли Вам обслуживание?
          </Typography>
        </CardContent>
        <CardActions
          disableSpacing={true}
          sx={{
            display: 'grid',
            alignSelf: 'stretch',
            padding: 0,
            gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))',
            gap: '16px 8px',
          }}
        >
          <Link
            className='link'
            to={getUpdatedSlugRoute(AppRoute.FEEDBACK_SUCCESS, slug, info)}
          >
            <Button
              variant='outlined'
              size='large'
              className='button success'
              fullWidth
            >
              Понравилось
            </Button>
          </Link>

          <Link
            className='link'
            to={getUpdatedSlugRoute(AppRoute.FEEDBACK_FAIL, slug, info)}
          >
            <Button
              variant='outlined'
              size='large'
              className='button error'
              fullWidth
            >
              Есть претензии
            </Button>
          </Link>
        </CardActions>
      </Card>
    </Box>
  );
}

const getUpdatedSlugRoute = (route: string, slug: string, info: IVisitInfo) => {
  return {
    pathname: route.replace(':slug', slug),
    search: `?name=${info.client.firstname}`,
  };
};

export default FeedbackPage;
