import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { IDoctor } from '../../../interfaces/doctor.interface';
import { createFeedback } from '../../../api';
import { Service } from '../../../constants/feedback';

type Props = {
  doctor: IDoctor;
  visitSlug: string;
};

function DoctorCard({ doctor, visitSlug }: Props) {
  const onDoctorSubmitClick = async () => {
    await createFeedback(visitSlug, 5, Service.PRO, doctor.id);

    const url = doctor?.feedbackUrl;

    window.location.replace(url!);
  };

  return (
    <Card sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <CardMedia
        component='img'
        sx={{ width: 120 }}
        image={doctor.imageUrl}
        alt={doctor.fio}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component='div' variant='h6' color='text.error'>
            {doctor.fio}
          </Typography>
          <Typography
            variant='subtitle1'
            color='text.secondary'
            component='div'
          >
            {doctor.profession}
          </Typography>
          <Typography variant='subtitle1' component='div'>
            Стаж: {doctor.experience}
          </Typography>
        </CardContent>

        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button onClick={onDoctorSubmitClick}>Оставить отзыв</Button>
        </CardActions>
      </Box>
    </Card>
  );
}

export default DoctorCard;
