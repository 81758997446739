import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import HomePage from './pages/homePage/HomePage';

import { appRoutes } from './routes/routes';
import { AppRoute } from './constants/routes';

const App = () => {
  return (
    <div className='app'>
      <Router>
        <Switch>
          <Route path={AppRoute.HOME} exact>
            <HomePage />
          </Route>
          {appRoutes()}
          <Redirect to={AppRoute.HOME} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
